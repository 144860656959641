<template>

  <div class="panel">
      <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">
            <div class="column is-size-6 has-text-centered">STORE DETAILS</div>
        </div>
      </div>
      <hr>
      <div class="form" v-if="getActiveStore.role != 'STAFF'">
        <form action="" @submit.prevent="handleSubmit">
        <div class="columns">
            <div class="column">
                <b-field label="Store Name"
                    >
                    <b-input type="text"
                        icon="briefcase"
                        v-model="store.storeName"
                        maxlength="40"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Company Name"
                    >
                    <b-input type="text"
                        icon="briefcase"
                        v-model="store.companyName"
                        maxlength="40"
                        disabled
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-field label="Email">
                    <b-input type="email"
                        icon="envelope"
                        v-model="store.email"
                        maxlength="100"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Mobile Phone">
                    <b-input type="tel"
                        icon="mobile"
                        v-model="store.cellNumber"
                        maxlength="30"
                        v-cleave="masks.creditCard"
                        @input.native="onInput"
                        :value="store.cellNumber"
                        required         
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Office Phone">
                    <b-input type="tel"
                        icon="mobile"
                        v-model="store.officeNumber"
                        maxlength="30"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-field label="Street">
                    
                    <b-input type="text"
                        icon="address-card"
                        v-model="store.addressLine1"
                        maxlength="30" 
                        required                  
                        >
                    </b-input>
                </b-field>   
            </div>
            <div class="column">
                <b-field label="City">
                    
                    <b-input type="text"
                        icon="address-card"
                        v-model="store.city"
                        maxlength="30" 
                        required                  
                        >
                    </b-input>
                </b-field>   
            </div>
        </div>

        <div class="columns">

            <div class="column">
                <!-- <b-field label="State">                            
                    <b-select
                        placeholder="State"
                        icon="map"
                        v-model="store.state">
                        <option
                            v-for="state in states"
                            :value="state[0]"
                            :key="state[0]">
                            {{ state[1] }}
                        </option>
                    </b-select>
                </b-field> -->
                <b-field label="State">
                    <b-select readonly v-model="store.state" placeholder="Select a State">
                        <option
                            v-for="option in states"
                            :value="option.code"
                            :key="option.code">
                            {{ option.code}} {{ option.name }} 
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Zip">
                    
                    <b-input type="text"
                        icon-pack="fas"
                        icon="address-card"
                        v-model="store.zipCode"
                        maxlength="10"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
            <b-field label="Country">
                <b-select 
                    placeholder="Select Country" 
                    v-model="store.country" 
                    required>
                    <option
                        v-for="country in countries"
                        :value="country[0]"
                        :key="country[0]">
                        {{ country[1] }}
                    </option>
                </b-select>		                               
            </b-field>
            </div>
            <div class="column">
                <b-field label="TimeZone">
                    <b-select 
                        placeholder="Select Time Zone" 
                        v-model="store.timezone"
                        icon="clock"
                        required>
                        <option
                            v-for="zone in zones"
                            :value="zone[0]"
                            :key="zone[0]">
                            {{ zone[1] }}
                        </option>
                    </b-select>		        
                </b-field>
            </div>
            <div class="column">
                <!-- 
                    <b-timepicker 
                        v-model="dailyRegisterClosingTime"
                        hour-format="12"
                        inline></b-timepicker>
                </b-field> -->
                <b-field label="Daily Register Closing Time">
                    <b-timepicker
                        placeholder="Click to select..."
                        icon="clock"
                        v-model="dailyRegisterClosingTime"
                        hour-format="12">
                    </b-timepicker>
                </b-field>
            </div>
        </div>
        <div>
            <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
            <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
            <p v-if="!formComplete">( Saving disabled temporarily )</p>
        </div>
        </form>

      </div>

        <div v-if="isLoading">
            <data-loading 
                :isLoading="isLoading" 
                :isFullPage="isFullPage"> 
            </data-loading>
        </div>

  </div>
    
</template>

<script>

import UserStore from "../../../../store/UserStore"

// // import SelectStates from "../../../components/app/SelectStates"
import axios from 'axios'
import ErrorMessage from '../../../../components/app/ErrorMessage'

import router from "../../../../router"
import DataLoading from '../../../../components/app/DataLoading.vue'

import Cleave from 'cleave.js'
const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input')
        input._vCleave = new Cleave(input, binding.value)
    },
    unbind(el) {
        const input = el.querySelector('input')
        input._vCleave.destroy()
    }
}

export default {
    components: {
        DataLoading,
        ErrorMessage
    },

    metaInfo: {
        title: 'Update Store Details'
    },

    directives: { cleave },

    data() {
        return {
            isLoading: false,
            isFullPage: true,
            showForm: true,
            formComplete: true,
            dailyRegisterClosingTime: new Date(2018, 11, 24,6,1),
            errors: [],
            store: [],
            storeStateCode: "",
            states: [],
            // states: [ [ 'FL', 'Florida'], [ 'IL', 'Illinois'], [ 'IN', 'Indiana'],  [ 'MI', 'Michigan'], [ 'WI', 'Wisconsin']],
            countries: [ ['US', 'USA'], ['CA','Canada'], ['MX','Mexico']],
            zones: [ ['CST','Central Time'], ['EST', 'Eastern Time'], ['MST','Mountain Time'], ['PST','Pacefic Time']],
            messagetype: 'is-warning',
            masks: {
                creditCard: { creditCard: true },
                numeral: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix: '$ '
                },
                custom: {
                    delimiters: ['.', '.', '-'],
                    blocks: [3, 3, 3, 2],
                    numericOnly: true
                }
            }
        }
    },

    methods: {

        fetchStore() {
			if (  UserStore.getters.getActiveStore.storeId ) {
				axios.get('/sapi/stores/' + UserStore.getters.getActiveStore.storeId, {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.store = JSON.parse(JSON.stringify(response.data.data))
                    this.dailyRegisterClosingTime = new Date(2018, 11, 24,this.store.reconcilationClosingHours, this.store.reconcilationClosingMinutes)
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        handleSubmit() {
			if (  UserStore.getters.getActiveStore.storeId ) {

                this.store.reconcilationClosingHours = this.dailyRegisterClosingTime.getHours()
                this.store.reconcilationClosingMinutes = this.dailyRegisterClosingTime.getMinutes()

                // console.log(this.store.reconcilationClosingHours , this.store.reconcilationClosingMinutes)
                
				axios.put('/sapi/companies/' + UserStore.getters.getActiveStore.companyId + '/stores', 
                    this.store,
                    {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    this.store = response.data.data
                    if (response.status === 200) {
                        this.postSuccess = true
                        this.$buefy.notification.open({
                                message: 'Store Information Updated',
                                type: 'is-success',
                                duration: 1000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    }
                    UserStore.dispatch("setActiveStoreDetails", this.store.id)
                    setTimeout(function() {
                        router.push({ path: '/' })
                    }, 1000);


				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        onInput(event) {
            this.rawValue = event.target._vCleave.getRawValue()
            this.value = event.target._vCleave.getFormattedValue()
            // console.log(this.value)
        },

        countryStates() {
            
            var url = process.env.VUE_APP_API_ROOT_URL + "/global/country/" + UserStore.getters.getStore.country + "/states/"
            // console.log(url)

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    // this.states = response.data.data

                    this.states = response.data.data.sort(function(a,b) {

                            return (a["code"] > b["code"]) ? 1 : (a["code"] < b["code"]) ? -1 : 0;

                        });
                    var storeObj = this.states.find(o => o.code === UserStore.getters.getStore.state)
                    this.storeStateCode = storeObj.code

                    this.isLoading = false
                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })            
            

        }

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    mounted() {
        if (UserStore.getters.getActiveStore.role != 'STAFF') {
            this.countryStates()
            this.fetchStore()
        }
    }


    
    
}
</script>